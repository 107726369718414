.formAsistencia{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    place-content: center;
    align-self: center;
    gap: 1rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.152);
    width: 60vh;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--blanco);
}
.formAsistencia img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-top: -4rem;
}
.formAsistencia h2{
    color: var(--text-color2);
}
.formAsistencia h3{
    color: var(--text-color2);
}
.formAsistencia fieldset{
    width: 100%;
}
.formAsistencia fieldset input{
   padding:  7px 10px;
   background-color: transparent;
}
.formAsistencia fieldset select{
    padding:  7px 10px;
    background-color: transparent;
    color: var(--text-color2);
 }
.formAsistencia button{
    width: 100%;
}
.layout{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    place-content: center;
    align-self: center;
    background-color: var(--bg-color);
    height: 100vh;
    padding: 100px 10%;
}
.btnLoading2{
    border-radius: 10rem;
    border: none;
    background-color: var(--loading);
    padding: 13px;
    color: var(--blanco);
    margin-top: 10px;
    font-size: 16px;
}
@media (max-width: 900px){
    .formAsistencia{
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.152);
        width: 100%;
        padding: 20px;
    }
    .layout{
        height: 100vh;
        padding: 100px 5%;
    }
    .formAsistencia img{
        width: 70px;
        height: 70px;
        margin-top: -3rem;
    }
}